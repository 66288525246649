import { emitter, experimentDebugger } from '@marvelapp/react-ab-test';
import { trackEvent } from '../trackingParty';

export const EXPERIMENT_ACTIONS = {
    DISPLAY: 'Display',
    BUY_SUBSCRIPTION: 'Buy subscription.',
    OPEN_UPGRADE_MODAL: 'Open upgrade modal.',
    WIN: 'Win'
};

export const initABTests = () => {
    if (process.env.AB_TEST_DEBUG === 'true') {
        experimentDebugger.enable();
    }

    emitter.addPlayListener((experimentName, variantName) => {
        trackEvent(`${experimentName}`, {
            experimentName,
            variantName,
            action: EXPERIMENT_ACTIONS.DISPLAY
        });
    });
};

export const trackExperiment = (experiment, action) => {
    const variantName = emitter.getActiveVariant(experiment.id);
    trackEvent(`${experiment.id}`, {
        experimentName: experiment.id,
        variantName,
        action
    });
};

export const winExperiment = (experiment) => {
    emitter.emitWin(experiment.id);

    trackExperiment(experiment, EXPERIMENT_ACTIONS.WIN);
};

export const createExperiment = (experiment) => {
    const variants = Object.values(experiment.variants);

    const variantNames = variants.map((v) => v.id);
    const variantTrafficPercentage = variants.map((v) => v.trafficPercentage);

    emitter.defineVariants(experiment.id, variantNames, variantTrafficPercentage);

    return {
        trackExperiment: (action) => trackExperiment(experiment, action),
        winExperiment: () => winExperiment(experiment)
    };
};
