import {
    EDIT_USER_COLLECTION,
    FETCH_USER_COLLECTION,
    FETCH_USER_COLLECTIONS,
    NEW_USER_COLLECTION,
    REMOVE_USER_COLLECTION,
    UPDATE_MOCKUPS_COLLECTION
} from '../actions/actionTypes';

const INITIAL_STATE = {
    userCollections: [],
    userCollection: {},
    hasLoadedCollections: false,
    hasLoadedCollectedMockups: false,
    hasCollectionChange: false,
    hasChangedStatus: false
};

export default function userCollectionsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case `${FETCH_USER_COLLECTIONS}_PENDING`:
            return { ...state, hasLoadedCollections: false };
        case `${FETCH_USER_COLLECTIONS}_FULFILLED`: {
            setCollectedMockups(action.payload);
            return { ...state, hasLoadedCollections: true, userCollections: action.payload };
        }
        case `${FETCH_USER_COLLECTION}_PENDING`:
            return { ...state, hasLoadedCollectedMockups: false };
        case `${FETCH_USER_COLLECTION}_FULFILLED`:
            return {
                ...state,
                hasLoadedCollectedMockups: true,
                userCollection: action.payload
            };
        case `${NEW_USER_COLLECTION}_PENDING`:
            return { ...state, hasCollectionChange: true };
        case `${NEW_USER_COLLECTION}_FULFILLED`:
            return {
                ...state,
                hasCollectionChange: false,
                userCollections: [...state.userCollections, action.payload]
            };
        case `${EDIT_USER_COLLECTION}_PENDING`:
            return { ...state, hasCollectionChange: true };
        case `${EDIT_USER_COLLECTION}_FULFILLED`: {
            const newObject = updateObject(state.userCollection, action.payload.Attributes);
            const newArray = updateObjectArray(state.userCollections, action.payload.Attributes);
            return {
                ...state,
                hasCollectionChange: false,
                userCollection: newObject,
                userCollections: newArray
            };
        }
        case `${REMOVE_USER_COLLECTION}_FULFILLED`: {
            const newArray = state.userCollections.filter(
                ({ timestamp }) => timestamp !== action.payload.timestamp
            );

            setCollectedMockups(newArray);
            return { ...state, userCollections: newArray };
        }
        case `${UPDATE_MOCKUPS_COLLECTION}_PENDING`:
            return { ...state, hasChangedStatus: true };
        case `${UPDATE_MOCKUPS_COLLECTION}_FULFILLED`: {
            const newArray = updateObjectArray(state.userCollections, action.payload);

            setCollectedMockups(newArray);
            return { ...state, hasChangedStatus: false, userCollections: newArray };
        }
        default:
            return state;
    }
}

function setCollectedMockups(data) {
    const array = [];
    data.forEach((collection) => array.push(...collection.mockups));

    localStorage.setItem('collectedMockups', array.toString());
}

function updateObject(actual, update) {
    return { ...actual, ...update };
}

function updateObjectArray(actual, update) {
    const index = actual.findIndex(({ timestamp }) => timestamp === parseInt(update.timestamp));
    const newArray = [...actual];
    newArray[index] = { ...actual[index], ...update };
    return newArray;
}
