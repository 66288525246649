import { isServer } from './envDetection';

export function saveArrayLocal(array, key) {
    if (isServer() || !Array.isArray(array)) return;
    try {
        localStorage.setItem(key, JSON.stringify(array));
    } catch (err) {
        console.log(err);
    }
}

export function getArrayLocal(key) {
    if (isServer()) {
        return [];
    }
    try {
        const array = localStorage.getItem(key);

        return array ? JSON.parse(array) : [];
    } catch (err) {
        console.log(err);
        return [];
    }
}

export function saveArrayCount(array, key) {
    if (isServer()) {
        return;
    }
    if (!array || !Array.isArray(array)) return;
    try {
        localStorage.setItem(key, array.length);
    } catch (err) {
        console.log(err);
    }
}

export function getArrayCount(key) {
    if (isServer()) {
        return 0;
    }
    try {
        const count = localStorage.getItem(key);

        return count ? Number(count) : 0;
    } catch (err) {
        console.log(err);
        return 0;
    }
}
