/**
 *
 * @returns String - possible values 'canvas' (webgl not supported). 'webgl', 'webgl2'
 */
export function getWebglSupportStatus() {
    if (isWebgl2Available()) {
        return 'webgl2';
    }

    if (isWebglAvailable()) {
        return 'webgl';
    }

    return 'canvas';
}

export function isWebglAvailable() {
    try {
        const canvas = document.createElement('canvas');
        return (
            !!window.WebGLRenderingContext &&
            (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
        );
    } catch (e) {
        return false;
    }
}
function isWebgl2Available() {
    try {
        const canvas = document.createElement('canvas');
        return !!(window.WebGL2RenderingContext && canvas.getContext('webgl2'));
    } catch (e) {
        return false;
    }
}

export function isSafari() {
    return window.safari !== undefined;
}

export function isIos() {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
}

export function isAndroid() {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('android') > -1; // && ua.indexOf("mobile");
}

export function isMobileOs() {
    return isAndroid() || isIos();
}

export function isMac() {
    return navigator.appVersion.indexOf('Mac') !== -1;
}

export function isOldIe() {
    if (typeof window === 'undefined') return false;
    const ua = window.navigator.userAgent; // Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE '); // IE 10 or older
    const trident = ua.indexOf('Trident/'); // IE 11
    return msie > 0 || trident > 0;
}

export function isTouchScreen() {
    return (
        'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
    );
}
