import React, { useEffect } from 'react';
import { wrapper } from '../store/store';
import LanguageProvider from '../containers/Common/LanguageProvider';
import '../styles/styles.global.scss';
import { install } from 'ga-gtag';
import NextNprogress from 'nextjs-progressbar';
import AuthProvider from '../containers/Common/AuthProvider';
import ErrorBoundary from '../containers/Common/ErrorBoundary';
import { initABTests } from '../utils/ABTest/ABTest';
import initOneTrust from '../utils/onetrust/onetrust';

// initialize lazysizes
// initial LQIP image replaced by full image once loaded
// src will be replaced by data-src/data-srcset
const canUseDOM = !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
);
if (canUseDOM) {
    import('lazysizes');
    import('lazysizes/plugins/parent-fit/ls.parent-fit');
}


const WrappedApp = ({ Component, pageProps, err }) => {
    useEffect(() => {
        // initialise the Google analytics tracking
        install('G-EN7C0PM0T5');
        initABTests();
        initOneTrust();
    }, []);

    return (
        <ErrorBoundary>
            <AuthProvider>
                <LanguageProvider>
                    <>
                        <NextNprogress
                            color="#00BAFF"
                            startPosition={0.3}
                            stopDelayMs={200}
                            height="3"
                            options={{ showSpinner: false }}
                        />
                        <Component {...pageProps} err={err} />
                    </>
                </LanguageProvider>
            </AuthProvider>
        </ErrorBoundary>
    );
};

export default wrapper.withRedux(WrappedApp);
