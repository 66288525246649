import {pushBrowserHistoryLocalised} from "./languageHelpers";

export function getAuthCurrentUrl() {
    const getCurrentUrl = localStorage.getItem('currentUrl');

    if (getCurrentUrl) {
        localStorage.removeItem('currentUrl');

        pushBrowserHistoryLocalised(getCurrentUrl);
    }
}

export function getLastMockupUrl() {
    const localstorageCurrentPreview = localStorage.getItem('currentPreview');
    return localstorageCurrentPreview ? `/mockup/${localstorageCurrentPreview}` : '/mockups';
}

export function setAuthCurrentUrl() {
    const localstorageCurrentPreview = localStorage.getItem('currentPreview');
    const currentUrl = localstorageCurrentPreview ? `/mockup/${localstorageCurrentPreview}` : window.location.pathname;
    const testCurrentUrl = !(currentUrl.search("/login") > -1 || currentUrl.search("/signup") > -1);

    if (testCurrentUrl) {
        localStorage.removeItem('currentPreview');
        localStorage.setItem('currentUrl', currentUrl);
    }
}
