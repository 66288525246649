export function isServer() {
    return typeof window === 'undefined';
}

export function isClient() {
    return typeof window !== 'undefined';
}

export function isCI() {
    return window !== 'undefined' && (window.Cypress || process.env.NODE_ENV === 'ci');
}
