import {
    CANCEL_DRAG,
    DEC_DRAG,
    GET_HOMEPAGE_DATA,
    GET_PLANS_PRICES,
    INC_DRAG,
    SET_ALERT,
    SET_LANGUAGE,
    SET_PREMIUM_MODAL,
    SET_PREVIOUS_LOCATION,
    SET_WEBGL_SUPPORT,
    SET_ACTIVE_COLLECTION
} from '../actions/actionTypes';

const INITIAL_STATE = {
    user: null,
    menuItems: [],
    unregistredUser: false,
    dragCounter: 0,
    isDragging: false,
    homepageData: {},
    previousLocation: '/',
    activeCollection: null,
    webglSupport: 'webgl',
    premiumModal: null,
    premiumModalSource: null,
    locale: null,
    localeStrings: null,
    alerts: {},
    planPrices: {
        527401: { amount: 108 / 12, symbol: '$', currency: 'USD' },
        527402: { amount: 14, symbol: '$', currency: 'USD' },
        542352: { amount: 19, symbol: '$', currency: 'USD' },
        542351: { amount: 24, symbol: '$', currency: 'USD' },
        527403: { amount: 348 / 12, symbol: '$', currency: 'USD' },
        575845: { amount: 84, symbol: '$', currency: 'USD' },
        575842: { amount: 124, symbol: '$', currency: 'USD' },
        575838: { amount: 164, symbol: '$', currency: 'USD' },
        575844: { amount: 828 / 12, symbol: '$', currency: 'USD' },
        575841: { amount: 1188 / 12, symbol: '$', currency: 'USD' },
        542357: { amount: 1548 / 12, symbol: '$', currency: 'USD' }
    }
};
let newDrag;
let isDragging;

export default function windowReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case `${GET_PLANS_PRICES  }_FULFILLED`:
            if (action.payload) {
                return { ...state, planPrices: action.payload };
            }
                return { ...state };


        case GET_HOMEPAGE_DATA:
            return { ...state, homepageData: action.payload };

        case INC_DRAG:
            newDrag = state.dragCounter + 1;
            isDragging = state.isDragging;
            if (newDrag >= 1) {
                isDragging = true;
            }

            return { ...state, dragCounter: newDrag, isDragging };

        case DEC_DRAG:
            newDrag = state.dragCounter - 1;
            isDragging = state.isDragging;
            if (newDrag <= 0) {
                isDragging = false;
                newDrag = 0;
            }

            return { ...state, dragCounter: newDrag, isDragging };

        case CANCEL_DRAG:
            return { ...state, dragCounter: 0, isDragging: false };

        case SET_PREVIOUS_LOCATION:
            // the only exception are welcome modals after plan change that should definitely stay
            // so in case the current premium modal is welcome modal, we'll just update the previous location
            if (state.premiumModal && state.premiumModal.indexOf('welcome') > -1) {
                return {
                    ...state,
                    previousLocation: action.payload
                };
            }

            return {
                ...state,
                previousLocation: action.payload,
                premiumModal: null,
                premiumModalSource: null
            };

        case SET_PREMIUM_MODAL:
            return {
                ...state,
                premiumModal: action.payload.type,
                premiumModalSource: action.payload.source
            };

        case SET_WEBGL_SUPPORT:
            return { ...state, webglSupport: action.payload };
        case SET_ALERT:
            return {
                ...state,
                alerts: updateAlerts(state.alerts, action.payload)
            };
        case `${SET_LANGUAGE}_FULFILLED`:
            return {
                ...state,
                locale: action.payload.locale,
                localeStrings: action.payload.translates[0],
                menuItems: action.payload.translates[1]
            };
        case SET_ACTIVE_COLLECTION:
            return {
                ...state,
                activeCollection: action.payload
            };
        default:
            return state;
    }
}

function updateAlerts(currentAlerts, payload) {
    const update = { ...currentAlerts};
    update[payload.alertId] = payload.alertData;
    if (payload.alertData === null) {
        delete update[payload.alertId];
    }

    return update;
}
