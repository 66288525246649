/* eslint-disable import/prefer-default-export */
import { PRIVATE_MOCKUP_ASSETS, PUBLIC_MOCKUP_ASSETS } from '../utils/variables';
import serviceClient, {mockupsService} from './ServiceClient';
import { getUserToken } from './helpers';

export async function getMockupAssets(mockup, width) {
    const token = await getUserToken();
    if (token) {
        return serviceClient.get(`${PRIVATE_MOCKUP_ASSETS}/${mockup.id}?w=${width}`);
    }
    return serviceClient.get(`${PUBLIC_MOCKUP_ASSETS}/${mockup.id}?w=${width}`);
}

export function getMockups(mockups) {
    return Promise.all(
        mockups.map((mockup) => {
            return mockupsService.get(`mockup/${decodeURIComponent(mockup)}`);
        })
    );
}
