let redirectSignIn;
let redirectSignOut;

if (process.env.NODE_ENV === 'development') {
    redirectSignIn = 'http://localhost:3000/';
    redirectSignOut = 'http://localhost:3000/';
}
if (process.env.NODE_ENV === 'production') {
    redirectSignIn = 'https://smartmockups.com/';
    redirectSignOut = 'https://smartmockups.com/';
}

const awsConfig = {
    aws_project_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_C0WIGqW5c',
    aws_user_pools_web_client_id: '2mip5ahhb3ub002aukg5r63bpp',
    oauth: {
        domain: 'auth.smartmockups.com',
        scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
        redirectSignIn,
        redirectSignOut,
        responseType: 'token'
    },
    federationTarget: 'COGNITO_USER_POOLS'
};

export default awsConfig;
