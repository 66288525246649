import React from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { localisePathname } from '../../../utils/languageHelpers';

const LinkLocalised = (props) => {
    const { activeClassName, to, router, shallow, title, isPrefetch, tabIndex } = props;
    const isActive = to === router.asPath;
    let className = props.className;

    if (activeClassName) {
        if (isActive) {
            if (activeClassName) {
                if (className) {
                    className += ` ${activeClassName}`;
                } else {
                    className = activeClassName;
                }
            }
        }
    }

    // disabled eslint rule as this is official approach from NextJS docs
    /* eslint-disable jsx-a11y/anchor-is-valid */
    // disabled eslint rule for handle keys and adding role. We don't need this.
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
        <Link href={localisePathname(to)} shallow={!!shallow} prefetch={isPrefetch ?? false}>
            <a className={className} title={title} tabIndex={tabIndex} onClick={props.onClick}>
                {props.children}
            </a>
        </Link>
    );
};

LinkLocalised.propTypes = {
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
    isPrefetch: PropTypes.bool,
    onClick: PropTypes.func
};

export default withRouter(LinkLocalised);
