import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { getBrowserLocale, isValidLocale } from '../../../utils/languageHelpers';
import { setLanguage } from '../../../actions/windowActions';
import { DEFAULT_LOCALE } from '../../../utils/locale/localeVariables';

const LanguageProvider = (props) => {
    const dispatch = useDispatch();
    const ssrlocale = useSelector((state) => state.server.locale);
    const ssrLocaleStrings = useSelector((state) => state.server.localeStrings);
    const locale = useSelector((state) => state.window.locale);
    const localeStrings = useSelector((state) => state.window.localeStrings);
    const authenticated = useSelector((state) => state.auth.authenticated);
    const user = useSelector((state) => state.auth.user);

    const router = useRouter();

    useEffect(() => {
        const prefferedLoc = getPreferedLocale();
        dispatch(setLanguage(prefferedLoc)).then(() => {
            // when user changes, check the user locale to set into URL
            // so language strings will be already correct on SSR
            if (router.locale !== prefferedLoc) {
                const url = router.asPath;
                router.push(url, url, { locale: prefferedLoc });
            }
        });
    }, [authenticated]);

    function getPreferedLocale() {
        if (window.Cypress) return DEFAULT_LOCALE;

        const currentLocale = router.locale ? router.locale : DEFAULT_LOCALE;

        if (user && user.locale) {
            return user.locale;
        }

        const languagePreference = localStorage.getItem('languagePreference');
        if (languagePreference) {
            return languagePreference;
        }

        const browserLang = getBrowserLocale();

        if (isValidLocale(browserLang)) {
            return browserLang;
        }

        return currentLocale;
    }

    return (
        <IntlProvider
            locale={locale || ssrlocale}
            messages={locale ? localeStrings : ssrLocaleStrings}
        >
            {React.Children.only(props.children)}
        </IntlProvider>
    );
};

LanguageProvider.propTypes = {
    children: PropTypes.element.isRequired
};

export default LanguageProvider;
