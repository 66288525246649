import ServiceClient from './ServiceClient';

// WARNING: promise returned by .request() is enhanced with .cancel() method. Do not use async
// in any functions above .request() (such as .post(), .get(), etc...) because the cancellation
// functionality would get lost.

/**
 * Generic Endpoint Class extender
 * to modify the request methods with parameter naming as URL is replaced by direct Endpoint
 */
export default class EndpointService extends ServiceClient {
    get(endpoint, options = {}) {
        return super.get(endpoint, options);
    }

    post(endpoint, payload, options = {}) {
        return super.post(endpoint, payload, options);
    }

    delete(endpoint, options = {}) {
        return super.delete(endpoint, options);
    }

    patch(endpoint, payload, options = {}) {
        return super.patch(endpoint, payload, options);
    }
}
