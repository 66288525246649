/* eslint no-underscore-dangle: 0 */
import { getUserToken } from '../helpers';

// WARNING: promise returned by .request() is enhanced with .cancel() method. Do not use async
// in any functions above .request() (such as .post(), .get(), etc...) because the cancellation
// functionality would get lost.

/**
 * Service Client Wrapper
 * that abstracts HTTP requests and wrap the logic in one place.
 */
export default class ServiceClient {
    options = {};

    /**
     * @param url
     */
    constructor(url) {
        this._url = url;
    }

    _ongoingRequests = 0;

    _maxRequests = 0;

    _requestsQueue = [];

    get shouldStageRequest() {
        return this._maxRequests !== 0 && this._ongoingRequests >= this._maxRequests;
    }

    setMaxConcurrentRequests(maxRequests) {
        this._maxRequests = maxRequests;
    }

    /**
     * Direct Method for GET request
     * @param url - can bear endpoint or full url
     * @param options {Object}
     * @returns {Promise<*>}
     */
    get(url, options = {}) {
        return this.request(url, { ...options, method: 'GET' });
    }

    /**
     * Direct Method for POST request
     * @param url - can bear endpoint or full url
     * @param payload - request body
     * @param options {Object}
     * @returns {Promise<*>}
     */
    post(url, payload, options = {}) {
        return this.request(url, { body: payload, ...options, method: 'POST' });
    }

    /**
     * Direct Method for PATCH request
     * @param url - can bear endpoint or full url
     * @param payload - request body
     * @param options {Object}
     * @returns {Promise<*>}
     */
    patch(url, payload, options = {}) {
        return this.request(url, { body: payload, ...options, method: 'PATCH' });
    }

    /**
     * Direct Method for DELETE request
     * @param url - can bear endpoint or full url
     * @param options {Object}
     * @returns {Promise<*>}
     */
    delete(url, options = {}) {
        return this.request(url, { ...options, method: 'DELETE' });
    }

    /**
     * Compose full url from endpoint and predefined api url
     * @param endpoint - can bear endpoint or full url
     * @returns {string}
     */
    _getEndpointUrl(endpoint) {
        if (!this._url) return endpoint;
        if (endpoint.startsWith('/')) {
            endpoint = endpoint.slice(1);
        }
        return `${this._url}/${endpoint}`;
    }

    /**
     * Wrapper for _request to make the
     * @param endpoint - can bear API endpoint only or full path to the endpoint
     * @param options
     * @returns {Promise<*>}
     */
    request(endpoint, options = {}) {
        const controller = typeof window === 'object' ? new AbortController() : undefined;
        options.signal = controller?.signal;
        const promise = this._request(endpoint, options);
        promise.cancelled = false;
        promise.cancel = () => {
            if (controller) {
                promise.cancelled = true;
                controller.abort();
            }
        };
        return promise;
    }

    /**
     * Generic Method for request call (actual logic)
     * @param endpoint - can bear API endpoint only or full path to the endpoint
     * @param body
     * @param customOptions {Object}
     * @param token
     * @returns {Promise<*>}
     * @private
     */
    async _request(endpoint, { body, token, ...customOptions } = {}) {
        // build url path
        const url = this._getEndpointUrl(endpoint);

        // check if Authorization header is not passed, keep it then
        const customAuthorization = customOptions?.headers?.Authorization;
        // if not and there is a token available, add to the authorization header value of the request
        if (!customAuthorization) {
            await this._setAuth(token);
        }

        // sets the configuration object for the request
        // default recognition of POST/GET method can be overridden by passing within option object
        // adds Authorization header if available in this.config
        const options = {
            method: body ? 'POST' : 'GET',
            ...customOptions,
            ...this.options,
            headers: {
                ...this.options.headers,
                ...customOptions.headers
            }
        };

        // add a body to the request
        if (body) {
            options.body = options.json ? body : JSON.stringify(body);
        }

        if (this.shouldStageRequest) {
            return this._stageRequest(url, options);
        }
        return this._sendRequest(url, options);
    }

    /**
     * Set Authorization Header token to the request
     * @param token - useful for passing manually the token for server side requests
     * @returns {Promise<void>}
     * @private
     */
    async _setAuth(token = null) {
        // use the provided token, possible case for server-side request
        if (token) {
            this.options.headers = {
                Authorization: token
            };
        } else if (typeof window !== 'undefined') {
            // otherwise automatically try to add the cached token stored locally
            const token = await getUserToken();
            if (token) {
                this.options.headers = {
                    Authorization: token
                };
            }
        }
    }

    /**
     * Stages the request call to queue.
     * @param url
     * @param options
     * @returns {Promise<*>}
     * @private
     */
    _stageRequest(url, options) {
        return new Promise((resolve) => {
            this._requestsQueue.push({ url, options, resolve });
        });
    }

    /**
     * Makes the request call.
     * @param url
     * @param options
     * @returns {Promise<*>}
     * @private
     */
    _sendRequest(url, options) {
        this._ongoingRequests++;
        return this._fetch(url, options);
    }

    /**
     * Safely subtracts one from _ongoingRequests.
     * @private
     */
    _subtractOngoingRequest() {
        this._ongoingRequests = Math.max(0, this._ongoingRequests - 1);
    }

    /**
     * Trigger next request waiting in queue.
     * @private
     */
    _triggerNextRequestInQueue() {
        if (this._requestsQueue.length > 0) {
            const { url, options, resolve } = this._requestsQueue.shift();
            this._sendRequest(url, options).then(resolve);
        }
    }

    /**
     * FETCH
     * @param url
     * @param config
     * @returns {Promise<any>}
     * @private
     */

    _fetch(url, config) {
        return fetch(url, config)
            .then(this._handleErrors)
            .then(this._interceptResponse)
            .then((res) => this._parseResponse(res, config))
            .catch(this._interceptError);
    }

    /**
     * Intercepts response to keep track of concurrent requests.
     * @private
     */
    _interceptResponse = (response) => {
        this._subtractOngoingRequest();
        this._triggerNextRequestInQueue();
        // TODO possibly handling the unauthorized call by clearing current sign-in session
        //  or whatever would be suitable behaviour
        // if (response.status === 401) {
        //     alert('ACCESS FORBIDDEN');
        //     return;
        // }
        return this._handleErrors(response);
    };

    /**
     * Intercepts error to keep track of concurrent requests and to filter non-errors.
     * @param error
     * @private
     */
    _interceptError = (err) => {
        this._subtractOngoingRequest();
        this._triggerNextRequestInQueue();
        return this._ignoreAbortError(err);
    };

    /**
     * Intercepts catch to keep track of concurrent requests.
     * Ignores non-errors `AbortError` that we don't want to track (cancellation).
     * @param error
     * @private
     */
    _ignoreAbortError(err) {
        // ignore when we cancelled the fetch.
        if (err.name === 'AbortError') return;
        return Promise.reject(err);
    }

    /**
     * Checks if the response is successful
     * if yes -> return it to be returned resolved
     * if no -> reject the promise with logging the error
     * @param response
     * @returns {Promise<{ok}|*>}
     * @private
     */
    _handleErrors = async (response) => {
        if (!response.ok) {
            const { status, statusText } = response;
            const errorMessage = await response.json();
            this._logError({ status, statusText, errorMessage });
            return Promise.reject(errorMessage);
        }
        return response;
    };

    /**
     * Prepare the error message for console log output
     * Note: currently tailored for Fetch Web API responses
     * Leaning to YAGNI principle :)
     * @param error
     * @private
     */
    _logError(error) {
        console.error(
            `ServiceClient error: ${error.status} ${error.statusText}`,
            error.errorMessage
        );
    }

    /**
     * Parse fetch response according to it's content-type header
     * @param response {Object}
     * @param config {Object}
     * @return {*|Promise<Blob>|Blob}
     * @private
     */
    _parseResponse(response, config) {
        const type = response.headers.get('content-type');
        if (type === 'application/json' || config.json) {
            return response.json();
        }
        if (type.startsWith('image/') || type.startsWith('video/')) {
            return response.blob();
        }
        return response.text();
    }
}
