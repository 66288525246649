import {
    SET_IS_INIT_MOCKUP_CREATOR, SET_MOCKUP_VIDEO_LOADING
} from '../actions/actionTypes';

const INITIAL_STATE = {
    isInitMockupCreator: false,
    isMockupVideoLoading: true,
};

export default function mockupCreatorReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_IS_INIT_MOCKUP_CREATOR:
            return { ...state, isInitMockupCreator: action.payload };
        case SET_MOCKUP_VIDEO_LOADING:
            return { ...state, isMockupVideoLoading: action.payload };
        default:
            return state;
    }
}
