/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux';
import mockupList from './mockupListReducer';
import mockupDetail from './mockupDetailReducer';
import mockupCreatorReducer from "./mockupCreatorReducer";
import integrationReducer from './integrationsReducer';
import windowReducers from './windowReducers';
import customMockupReducer from './customMockupReducer';
import serverSideReducer from './serverSideReducer';
import authReducer from './authReducer';
import userCollections from "./userCollectionsReducer";
import paddleReducer from "./paddleReducer";
import indexedPreviewHistoryReducer from "./indexedPreviewHistoryReducer";

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
const appReducer = combineReducers({
    mockupDetail,
    mockupCreatorReducer,
    mockupsList: mockupList,
    integrations: integrationReducer,
    window: windowReducers,
    customMockups: customMockupReducer,
    userCollections,
    server: serverSideReducer,
    auth: authReducer,
    paddle: paddleReducer,
    indexedHistory: indexedPreviewHistoryReducer
});

const combinedReducer = (state, action) => {
    // Clear all data in redux store to initial.
    if (action.type === 'RESET_STATE') {
        state = undefined;
    }
    return appReducer(state, action);
};
export default combinedReducer;
