import {
    setTrackUserId,
    setTrackUserProperties,
} from '../../utils/trackingParty';
import { saveArrayCount, saveArrayLocal } from '../../utils/localStorageHelpers';

export function orderPresets(presets) {
    const withId = presets.map((item, index) => {
        item.id = index;
        return item;
    });

    return withId.sort((a, b) => {
        return a.title.localeCompare(b.title);
    });
}

export function mergeObject(original, update) {
    return { ...original, ...update };
}

export function mergeBranding(original, update) {
    if (!update || Object.keys(update).length === 0) return { ...original };

    // compatibility with new data where watermarks are arrays (for multiple watermarks support)
    const isWatermark = update && update.watermarks && update.watermarks.length > 0;

    if (isWatermark) {
        update.watermark = update.watermarks[0].key;
        update.watermarkWidth = update.watermarks[0].width;
        update.watermarkPosition = update.watermarks[0].position;
    }

    return { ...original, ...update };
}

// TODO: pricing_plans - should we still check against isPro?
export function setUserTracking(user) {
    if (!user) {
        return;
    }
    setTrackUserId(user.username);

    let planType = 'Free';
    if (user.isTrial) {
        planType = 'Trial';
    } else if (user.teams || user.isTeamOwner) {
        planType = 'Team';
    } else if (user.isPro) {
        planType = 'Pro';
    } else if (user.isPremium) {
        planType = 'Premium';
    }

    const data = {
        email: user.email,
        planType,
        planId: user.planId ? user.planId : null,
        firstName: user.given_name,
        lastName: user.family_name
    };

    setTrackUserProperties(data);
}

export function eraseUserLocalStorageData(user) {
    if (user) return;
    saveArrayLocal([], 'customMockupsCategories');
    saveArrayCount([], 'customMockupCount');
}
