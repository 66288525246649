import {
    DELETE_HISTORY,
    DUPLICATE_HISTORY,
    EMPTY_SEARCH_RESULTS,
    EMPTY_SUBCATEGORY,
    FETCH_HISTORY_LIST,
    FETCH_HISTORY_LIST_PAGE,
    GET_SEARCH_RESULTS,
    SET_ACTIVE_TAGS,
    SET_LAST_VISITED_COLLECTION,
    SET_ORDER,
    SET_LIVE_PREVIEW,
    FETCH_MOCKUPS_FOR_BUNDLE
} from '../actions/actionTypes';

const INITIAL_STATE = {
    mockups: [],
    latestMockups: [],
    subcategoryTags: {},
    subcategoryLabels: {},
    totalMockups: 1803,
    selectedCategory: [],
    activeTags: [],
    searchResults: null,
    historyList: [],
    historyListPagination: null,
    order: 'publishDate',
    lastVisitedCollection: null,
    bundleMockups: [],
    isBundleLoaded: false,
    livePreview: null
};

export default function mockupListReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case EMPTY_SUBCATEGORY:
            return {
                ...state,
                mockups: []
            };

        case SET_ACTIVE_TAGS:
            return { ...state, activeTags: action.payload };

        case SET_ORDER:
            return { ...state, order: action.payload };

        case SET_LIVE_PREVIEW: {
            if (action.payload === undefined) {
                return { ...state, livePreview: undefined };
            }
            const livePreview = { ...(state.livePreview || {}), ...(action.payload || {}) };
            return { ...state, livePreview };
        }

        case `${FETCH_HISTORY_LIST}_FULFILLED`:
            return {
                ...state,
                historyList: action.payload.items || action.payload,
                historyListPagination: action.payload.continuation
            };

        case `${DUPLICATE_HISTORY}_FULFILLED`:
            return {
                ...state,
                historyList: [action.payload, ...state.historyList],
                historyListPagination: getHistoryListPagination(action.payload)
            };

        case `${FETCH_HISTORY_LIST_PAGE}_FULFILLED`:
            return {
                ...state,
                historyList: [...state.historyList, ...action.payload.items],
                historyListPagination: action.payload.continuation
            };

        case `${DELETE_HISTORY}_FULFILLED`:
            return {
                ...state,
                historyList: state.historyList.filter((item) => {
                    return item.timestamp !== action.payload.timestamp;
                })
            };

        case `${GET_SEARCH_RESULTS}_FULFILLED`:
            console.log(action.payload);
            return {
                ...state,
                searchResults: sanitizeSearchResults(action.payload.hits)
            };

        case EMPTY_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: null
            };

        case SET_LAST_VISITED_COLLECTION:
            return {
                ...state,
                lastVisitedCollection: action.payload
            };

        case FETCH_MOCKUPS_FOR_BUNDLE:
        case `${FETCH_MOCKUPS_FOR_BUNDLE}_FULFILLED`:
            return {
                ...state,
                bundleMockups: getMockups(action.payload),
                isBundleLoaded: true
            };

        default:
            return state;
    }
}

function getMockups(data) {
    return data;
}

function sanitizeSearchResults(searchResults) {
    /* eslint-disable no-underscore-dangle */
    const results = searchResults.map((item) => {
        if (item._highlightResult) {
            delete item._highlightResult;
        }
        return item;
    });
    return results;
}

function getHistoryListPagination(data) {
    if (!data.LastEvaluatedKey) return null;

    return data.LastEvaluatedKey.timestamp;
}
