import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './Loader.module.scss';
export default class Loader extends Component {
    render() {
        let colorClass = '';
        if (this.props.design === 'grey') {
            colorClass = styles.spinnerGrey;
        }
        if (this.props.design === 'darkGrey') {
            colorClass = styles.darkGrey;
        }
        return (
            <span
                className={`${styles.spinner} ${colorClass} ${
                    this.props.size === 'small' ? styles.small : ''
                }`}
            >
                <span className={styles.bounce1} />
                <span className={styles.bounce2} />
                <span className={styles.bounce3} />
            </span>
        );
    }
}

Loader.propTypes = {
    design: PropTypes.oneOf(['white', 'grey', 'darkGrey']),
    size: PropTypes.oneOf(['default', 'small'])
};
