import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPremiumModal } from '../../../actions/windowActions';

/**
 * This hook will gather all applied identity providers
 * individual federated identity providers can be plugged in
 * here as dedicated custom hooks IF they require some logic to be handled
 * Like for example:
 * - useGoogleIdentityProvider
 * - useFacebookIdentityProvider
 * - useOktaIdentityProvider
 * - ...
 * Idea of this hook is to be loaded in AuthProvider so it does not have to care any deeper
 * about what is going on there - as a matter of separation of concern pattern
 *
 * ---------------------------------------
 * Example of using external provider hook
 * ---------------------------------------
 * const googleIdentityProvider = useGoogleIdentityProvider();
 * useEffect(() => {
 *     if (!auth.authenticated) {
 *         googleIdentityProvider.init();
 *     }
 * }, [auth]);
 */
const useFederatedIdentityProviders = () => {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    // // this triggers the custom auth flow for linking the external provider user
    // // to a native cognito user and re-sign-in to it
    useEffect(() => {
        if (auth.initialFederatedSignIn) {
            dispatch(setPremiumModal('trial-welcome'));
        }
    }, [auth.initialFederatedSignIn]);
};

export default useFederatedIdentityProviders;
