// Mockup list actions
export const FETCH_MOCKUPS_FOR_CATEGORY = 'FETCH_MOCKUPS_FOR_CATEGORY';
export const EMPTY_SUBCATEGORY = 'EMPTY_SUBCATEGORY';
export const SET_ACTIVE_TAGS = 'SET_ACTIVE_TAGS';
export const SET_ORDER = 'SET_ORDER';
export const SET_LIVE_PREVIEW = 'SET_LIVE_PREVIEW';
export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
export const EMPTY_SEARCH_RESULTS = 'EMPTY_SEARCH_RESULTS';
export const FETCH_HISTORY_LIST = 'FETCH_HISTORY_LIST';
export const FETCH_HISTORY_LIST_PAGE = 'FETCH_HISTORY_LIST_PAGE';
export const DELETE_HISTORY = 'DELETE_HISTORY';
export const FETCH_MOCKUPS_FOR_BUNDLE = 'FETCH_MOCKUPS_FOR_BUNDLE';
export const SET_LAST_VISITED_COLLECTION = 'SET_LAST_VISITED_COLLECTION';

// Mockup details actions
export const SAVE_SCREEN = 'SAVE_SCREEN';
export const SET_MOCKUP = 'SET_MOCKUP';
export const FETCH_MOCKUP = 'FETCH_MOCKUP';
export const FETCH_MOCKUP_CLIENT_SIDE = 'FETCH_MOCKUP_CLIENT_SIDE';
export const SET_MOCKUP_KEEP_SCREEN = 'SET_MOCKUP_KEEP_SCREEN';
export const SET_AUTHOR = 'SET_AUTHOR';
export const CHANGE_SELECTED_SCREEN = 'CHANGE_SELECTED_SCREEN';
export const SET_CROPPER = 'SET_CROPPER';
export const SET_BACKGROUND = 'SET_BACKGROUND';
export const HIGHLIGHT_SCREEN = 'HIGHLIGHT_SCREEN';
export const SET_MOCKUP_LOADING = 'SET_MOCKUP_LOADING';
export const SET_MOCKUP_VIDEO_LOADING = 'SET_MOCKUP_VIDEO_LOADING';
export const SET_DOF = 'SET_DOF';
export const SET_VARIATION = 'SET_VARIATION';
export const SET_CANVAS_CROPPER = 'SET_CANVAS_CROPPER';
export const SET_CANVAS_CROPPER_AREA = 'SET_CANVAS_CROPPER_AREA';
export const FETCH_AVAILABLE_SIZES = 'FETCH_AVAILABLE_SIZES';
export const SET_BG_IMAGES = 'SET_BG_IMAGES';
export const ADD_BG_IMAGES = 'ADD_BG_IMAGES';
export const SET_COLOR_MASK_COLOR = 'SET_COLOR_MASK_COLOR';
export const SET_COLOR_PALETTE = 'SET_COLOR_PALETTE';
export const SET_LOGO_SHOW = 'SET_LOGO_SHOW';
export const SET_SHADOWS_SHOW = 'SET_SHADOWS_SHOW';
export const SET_CONTROL_SECTION_TAB = 'SET_CONTROL_SECTION_TAB';
export const EMPTY_MOCKUP = 'EMPTY_MOCKUP';
export const SET_SIMILAR_MOCKUPS = 'SET_SIMILAR_MOCKUPS';
export const SET_BRANDING_WATERMARK = 'SET_BRANDING_WATERMARK';
export const ADD_HISTORY = 'ADD_HISTORY';
export const GET_HISTORY = 'GET_HISTORY';
export const DUPLICATE_HISTORY = 'DUPLICATE_HISTORY';
export const UPLOAD_HISTORY_SCREENS = 'UPLOAD_HISTORY_SCREENS';
export const ADD_UPLOADED_VIDEO_TO_SCREEN = 'ADD_UPLOADED_VIDEO_TO_SCREEN';
export const SET_FILEPICKER = 'SET_FILEPICKER';
export const SET_LIVE_PREVIEW_TO_MOCKUP = 'SET_LIVE_PREVIEW_TO_MOCKUP';
export const SET_MOCKUP_DETAIL = 'SET_MOCKUP_DETAIL';
export const SET_DIRTY = 'SET_DIRTY';
export const SET_DIRTY_TSHIRT_COLOR = 'SET_DIRTY_TSHIRT_COLOR';
export const SET_DIRTY_SCREEN = 'SET_DIRTY_SCREEN';
export const SET_IS_INITIAL_STATE = 'SET_IS_INITIAL_STATE';
export const SET_IS_DOWNLOADED_LAST_MOCKUP = 'SET_IS_DOWNLOADED_LAST_MOCKUP';
export const SET_THUMB = 'SET_THUMB';
export const SET_MOCKUP_IS_INITIALIZED = 'SET_MOCKUP_IS_INITIALIZED';
export const SET_IS_FORBIDDEN_LEAVE_ALERT = 'SET_IS_FORBIDDEN_LEAVE_ALERT';
export const SET_IS_INIT_MOCKUP_CREATOR = 'SET_IS_INIT_MOCKUP_CREATOR';

// Integrations actions
export const FETCH_CANVA_IMAGES = 'FETCH_CANVA_IMAGES';
export const DELETE_CANVA_IMAGE = 'DELETE_CANVA_IMAGE';

// Custom Mockups action
export const GET_CUSTOM_MOCKUP = 'GET_CUSTOM_MOCKUP';
export const FETCH_CUSTOM_MOCKUP = 'FETCH_CUSTOM_MOCKUP';
export const EMPTY_CUSTOM_MOCKUP = 'EMPTY_CUSTOM_MOCKUP';
export const EMPTY_CUSTOM_MOCKUPS = 'EMPTY_CUSTOM_MOCKUPS';
export const DELETE_CUSTOM_MOCKUP = 'DELETE_CUSTOM_MOCKUP';
export const UPDATE_CUSTOM_MOCKUP = 'UPDATE_CUSTOM_MOCKUP';

// User collections action
export const FETCH_USER_COLLECTIONS = 'FETCH_USER_COLLECTIONS';
export const FETCH_USER_COLLECTION = 'FETCH_USER_COLLECTION';
export const NEW_USER_COLLECTION = 'NEW_USER_COLLECTION';
export const EDIT_USER_COLLECTION = 'EDIT_USER_COLLECTION';
export const REMOVE_USER_COLLECTION = 'REMOVE_USER_COLLECTION';
export const UPDATE_MOCKUPS_COLLECTION = 'UPDATE_MOCKUPS_COLLECTION';

// Landing pages
export const FETCH_LANDING_PAGE = 'FETCH_LANDING_PAGE';
export const FETCH_LANDING_PAGES_PREVIEWS = 'FETCH_LANDING_PAGES_PREVIEWS';

// Preset actions
export const UPDATE_PRESET = 'UPDATE_PRESET ';

// Window actions
export const INC_DRAG = 'INC_DRAG';
export const DEC_DRAG = 'DEC_DRAG';
export const CANCEL_DRAG = 'CANCEL_DRAG';
export const GET_HOMEPAGE_DATA = 'GET_HOMEPAGE_DATA';
export const GET_HOMEPAGE_MOCKUPS = 'GET_HOMEPAGE_MOCKUPS';
export const SET_PREVIOUS_LOCATION = 'SET_PREVIOUS_LOCATION';
export const SET_WEBGL_SUPPORT = 'SET_WEBGL_SUPPORT';
export const SET_PREMIUM_MODAL = 'SET_PREMIUM_MODAL';
export const HIDE_TRIAL_MODAL = 'HIDE_TRIAL_MODAL';
export const GET_PLANS_PRICES = 'GET_PLANS_PRICES';
export const SET_ALERT = 'SET_ALERT';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_LANGUAGE_SERVER = 'SET_LANGUAGE_SERVER';
export const SET_ACTIVE_COLLECTION = 'SET_ACTIVE_COLLECTION';
export const SET_TOTAL_COUNT_PADDLE = 'SET_TOTAL_COUNT_PADDLE';
export const SET_PADDLE_EVENT = 'SET_PADDLE_EVENT';
export const SET_PADDLE_ACTIVE = 'SET_PADDLE_ACTIVE';
export const SET_INDEXED_PREVIEW_HISTORY = 'SET_INDEXED_PREVIEW_HISTORY';
export const SET_INDEXED_PREVIEW_HISTORY_COUNT = 'SET_INDEXED_PREVIEW_HISTORY_COUNT';
export const SET_INDEXED_PREVIEW_HISTORY_REFRESH = 'SET_INDEXED_PREVIEW_HISTORY_REFRESH';

// Auth actions
export const AUTH_USER = 'AUTH_USER';
export const AUTH_NEW_PASSWORD_REQUIRED = 'auth_new_password_required';
export const UNAUTH_USER = 'unauth_user';
export const REGISTER_USER = 'register_user';
export const REGISTER_USER_CONFIRM = 'register_user_confirm';
export const REGISTER_MFA = 'register_mfa';
export const REGISTER_USER_ERROR = 'register_user_error';
export const FORGOT_PASSWORD = 'forgot_password';
export const FORGOT_PASSWORD_CONFIRM = 'forgot_password_confirm';
export const AUTH_ERROR = 'auth_error';
export const AUTH_ERROR_CLEAR = 'auth_error_clear';
export const UNSET_FORGOT_PASSWORD = 'unset_forgot_password';
// additional userData actions under Auth
export const GET_USER_PAYMENTS = 'GET_USER_PAYMENTS';
export const SET_USER = 'SET_USER';
export const SET_UNREGISTERED_USER = 'SET_UNREGISTERED_USER';
export const SET_BRANDING = 'SET_BRANDING';
export const CHANGE_ATTRIBUTE = 'CHANGE_ATTRIBUTE';
export const SET_USER_LOCALE = 'SET_USER_LOCALE';
export const INIT_USER_PASSWORD = 'INIT_USER_PASSWORD';
export const AUTH_FEDERATED_SIGN_IN = 'AUTH_FEDERATED_SIGN_IN';
export const VERIFY_USER_EMAIL = 'VERIFY_USER_EMAIL';
export const UNSET_VERIFY_USER_EMAIL = 'UNSET_VERIFY_USER_EMAIL';
export const VERIFY_USER_EMAIL_SUCCESS = 'VERIFY_USER_EMAIL_SUCCESS';
export const VERIFY_USER_EMAIL_ERROR = 'VERIFY_USER_EMAIL_ERROR';
export const UNSET_REQUEST_CHANGE_USER_EMAIL = 'UNSET_REQUEST_CHANGE_USER_EMAIL';
export const REQUEST_CHANGE_USER_EMAIL = 'REQUEST_CHANGE_USER_EMAIL';

// destroy session action to clear redux state into initial_state
export const RESET_STATE = 'RESET_STATE';
