import {
    eraseUserLocalStorageData,
    mergeBranding,
    orderPresets,
    setUserTracking
} from './helpers';

const setUser = (state, action) => {
    setUserTracking(action.payload);
    eraseUserLocalStorageData(action.payload);

    // decompose user object
    const user = action.payload;
    const isUserWithoutAccount = user === null;

    const cropPresets = user && user.cropPresets ? orderPresets(user.cropPresets) : [];
    const branding = user && user.branding ? user.branding : {};
    return {
        ...state,
        user,
        isUserWithoutAccount,
        isUserLoaded: true,
        cropPresets,
        branding: mergeBranding(state.branding, branding)
    };
};

export default setUser;
