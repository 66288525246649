import { PLANS, USER_API_URL } from '../utils/variables';
import Axios from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map';
import { userService } from './ServiceClient';

export async function fetchPlanPrices() {
    try {
        const plans = [];
        for (const frequency of Object.keys(PLANS)) {
            const p = Object.keys(PLANS[frequency]).map((item) => {
                return PLANS[frequency][item];
            });
            plans.push(...p);
        }

        const res = await Axios.get(`${USER_API_URL}/prices?product_ids=${plans.toString()}`);

        if (!res.data.success) {
            return null;
        }

        const prices = {};
        for (const product of res.data.response.products) {
            const months = product.subscription.interval === 'year' ? 12 : 1;
            prices[product.product_id] = {
                amount: product.price.gross / months,
                symbol: getSymbolFromCurrency(product.currency),
                currency: product.currency
            };
        }

        return prices;
    } catch (err) {
        return null;
    }
}

export function paddleUpdate(planId, subscriptionId) {
    return new Promise((resolve, reject) => {
        userService
            .post(`paddle-update`, {
                subscriptionId,
                planId
            })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
