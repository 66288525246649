import {
    SET_INDEXED_PREVIEW_HISTORY,
    SET_INDEXED_PREVIEW_HISTORY_COUNT,
    SET_INDEXED_PREVIEW_HISTORY_REFRESH
} from '../actions/actionTypes';


const INITIAL_STATE = {
    previewHistory: {},
    previewHistoryCount: 0,
    isRefreshPreviewHistory: false
};

export default function indexedPreviewHistoryReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_INDEXED_PREVIEW_HISTORY:
            return {
                ...state,
                previewHistory: action.payload
            };
        case SET_INDEXED_PREVIEW_HISTORY_COUNT:
            return {
                ...state,
                previewHistoryCount: action.payload
            };
        case SET_INDEXED_PREVIEW_HISTORY_REFRESH:
            return {
                ...state,
                isRefreshPreviewHistory: action.payload
            };
        default:
            return state;
    }
}
