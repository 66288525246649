import ServiceClient from './ServiceClient';
import EndpointService from './EndpointService';
import {
    BRANDING_API,
    CUSTOM_MOCKUPS_API,
    HISTORY_API,
    INTEGRATION_API,
    MOCKUPS_API,
    PRESET_API,
    USER_API_URL,
    USER_COLLECTIONS_API,
    RENDERER_API
} from '../../utils/variables';

export default new ServiceClient();
// individual service instances
export const userCollectionsService = new EndpointService(USER_COLLECTIONS_API);
export const mockupsService = new EndpointService(MOCKUPS_API);
export const userService = new EndpointService(USER_API_URL);
export const brandingService = new EndpointService(BRANDING_API);
export const presetService = new EndpointService(PRESET_API);
export const historyService = new EndpointService(HISTORY_API);
export const customMockupsService = new EndpointService(CUSTOM_MOCKUPS_API);
export const integrationService = new EndpointService(INTEGRATION_API);
export const rendererService = new EndpointService(RENDERER_API);
rendererService.setMaxConcurrentRequests(8);
