import {
    AUTH_USER,
    AUTH_NEW_PASSWORD_REQUIRED,
    UNAUTH_USER,
    REGISTER_USER,
    REGISTER_USER_CONFIRM,
    REGISTER_MFA,
    REGISTER_USER_ERROR,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_CONFIRM,
    UNSET_FORGOT_PASSWORD,
    AUTH_ERROR,
    AUTH_ERROR_CLEAR,
    SET_USER,
    CHANGE_ATTRIBUTE,
    SET_UNREGISTERED_USER,
    SET_BRANDING,
    UPDATE_PRESET,
    GET_USER_PAYMENTS,
    SET_USER_LOCALE,
    INIT_USER_PASSWORD,
    AUTH_FEDERATED_SIGN_IN,
    VERIFY_USER_EMAIL,
    VERIFY_USER_EMAIL_SUCCESS,
    VERIFY_USER_EMAIL_ERROR,
    REQUEST_CHANGE_USER_EMAIL,
    UNSET_REQUEST_CHANGE_USER_EMAIL
} from '../actions/actionTypes';
import { mergeBranding, orderPresets, setUserTracking } from './UserData/helpers';
import setUser from './UserData/setUser';

// do not put authenticated property into initial state, it would prevent SSR Auth during hydration
const INITIAL_STATE = {
    user: null,
    isUserWithoutAccount: false,
    isUserLoaded: false,
    cropPresets: [],
    payments: [],
    branding: { colorPalettes: [], watermarks: [] },
    signInCallback: false,
    initialFederatedSignIn: false
};

export default function authReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AUTH_USER: {
            // decompose user object
            const user = action.payload.user ? action.payload.user : null;
            const isUserWithoutAccount = user === null;
            setUserTracking(user);
            const cropPresets = user && user.cropPresets ? orderPresets(user.cropPresets) : [];
            const branding =
                user && user.branding ? user.branding : { colorPalettes: [], watermarks: [] };
            const returnProps = {
                ...state,
                user,
                isUserWithoutAccount,
                isUserLoaded: true,
                cropPresets,
                branding: mergeBranding(state.branding, branding),
                error: '',
                authenticated: !!user,
                signInCallback: !!action.payload.signInCallback,
                initialFederatedSignIn: !!action.payload.initialFederatedSignIn,
                register: false
            };

            console.log('auth_reducer. action: ', action);
            console.log('auth_reducer. returning props: ', returnProps);
            return returnProps;
        }
        case AUTH_FEDERATED_SIGN_IN: {
            const returnProps = {
                ...state,
                error: '',
                initialFederatedSignIn: !!action.payload.initialFederatedSignIn
            };

            console.log('auth_reducer. action: ', action);
            console.log('auth_reducer. returning props: ', returnProps);
            return returnProps;
        }
        case AUTH_NEW_PASSWORD_REQUIRED: {
            const returnProps = {
                ...state,
                error: '',
                authenticated: false,
                changePassword: true,
                cognitoUser: action.payload
            };

            console.log('auth_reducer. action: ', action);
            console.log('auth_reducer. returning props: ', returnProps);
            return returnProps;
        }
        case UNAUTH_USER: {
            const returnProps = {
                user: null,
                isUserWithoutAccount: true,
                isUserLoaded: true,
                cropPresets: [],
                payments: [],
                branding: { colorPalettes: [], watermarks: [] },
                error: '',
                authenticated: false,
                initialFederatedSignIn: false
            };

            console.log('auth_reducer. action: ', action);
            console.log('auth_reducer. returning props: ', returnProps);
            return returnProps;
        }
        case REGISTER_USER: {
            const returnProps = {
                ...state,
                error: '',
                authenticated: action.payload.userConfirmed,
                register: true
            };

            console.log('auth_reducer. action: ', action);
            console.log('auth_reducer. returning props: ', returnProps);
            return returnProps;
        }
        case REGISTER_MFA: {
            const returnProps = {
                ...state,
                error: '',
                mfa: true,
                resendCode: false,
                cognitoUser: action.payload
            };

            console.log('auth_reducer. action: ', action);
            console.log('auth_reducer. returning props: ', returnProps);
            return returnProps;
        }
        case REGISTER_USER_CONFIRM: {
            const returnProps = {
                ...state,
                error: '',
                authenticated: false,
                register: true,
                mfa: false,
                resendCode: false
            };

            console.log('auth_reducer. action: ', action);
            console.log('auth_reducer. returning props: ', returnProps);
            return returnProps;
        }

        case REGISTER_USER_ERROR: {
            const returnProps = {
                ...state,
                error: action.payload,
                mfa: true,
                resendCode: true,
                cognitoUser: action.cognitoUser
            };

            console.log('auth_reducer. action: ', action);
            console.log('auth_reducer. returning props: ', returnProps);
            return returnProps;
        }
        case AUTH_ERROR: {
            const returnProps = {
                ...state,
                error: action.payload,
                authenticated: false,
                mfa: false,
                register: false
            };

            console.log('auth_reducer. action: ', action);
            console.log('auth_reducer. returning props: ', returnProps);
            return returnProps;
        }
        case FORGOT_PASSWORD: {
            const returnProps = {
                ...state,
                error: '',
                forgotPasswordCode: true,
                authenticated: false,
                username: action.username
            };

            console.log('auth_reducer. action: ', action);
            console.log('auth_reducer. returning props: ', returnProps);
            return returnProps;
        }
        case UNSET_FORGOT_PASSWORD: {
            const returnProps = {
                ...state,
                error: '',
                forgotPasswordCode: false,
                authenticated: false,
                username: null
            };

            console.log('auth_reducer. action: ', action);
            console.log('auth_reducer. returning props: ', returnProps);
            return returnProps;
        }
        case FORGOT_PASSWORD_CONFIRM: {
            const returnProps = {
                ...state,
                error: '',
                forgotPasswordCode: false,
                authenticated: false
            };

            console.log('auth_reducer. action: ', action);
            console.log('auth_reducer. returning props: ', returnProps);
            return returnProps;
        }
        case AUTH_ERROR_CLEAR: {
            const returnProps = { ...state, error: '', authenticated: false };

            console.log('auth_reducer. action: ', action);
            console.log('auth_reducer. returning props: ', returnProps);
            return returnProps;
        }

        // EMAIL CHANGE
        case REQUEST_CHANGE_USER_EMAIL: {
            const returnProps = {
                ...state,
                error: '',
                requestedUserEmailChange: true
            };
            return returnProps;
        }
        case UNSET_REQUEST_CHANGE_USER_EMAIL: {
            const returnProps = {
                ...state,
                error: '',
                requestedUserEmailChange: false
            };
            return returnProps;
        }
        case VERIFY_USER_EMAIL: {
            const returnProps = {
                ...state,
                error: '',
                verifyUserAttributeCode: true
            };
            return returnProps;
        }
        case VERIFY_USER_EMAIL_ERROR: {
            const returnProps = {
                ...state,
                error: action.payload.error
            };
            return returnProps;
        }
        case VERIFY_USER_EMAIL_SUCCESS: {
            const newUser = { ...state.user };
            newUser.email = action.payload.email;

            const returnProps = {
                ...state,
                error: '',
                verifyUserAttributeCode: false,
                verifyUserAttributeDone: true,
                user: newUser
            };
            return returnProps;
        }

        // USER DATA
        case SET_USER:
            return setUser(state, action);
        case `${CHANGE_ATTRIBUTE}_FULFILLED`: {
            const newUser = { ...state.user };
            newUser[action.payload.name] = action.payload.value;
            return { ...state, user: newUser };
        }
        case SET_UNREGISTERED_USER: {
            return {
                ...state,
                isUserWithoutAccount: action.payload,
                isUserLoaded: true
            };
        }
        case `${SET_BRANDING}_FULFILLED`: {
            return {
                ...state,
                branding: mergeBranding(state.branding, action.payload)
            };
        }
        case `${UPDATE_PRESET}_FULFILLED`: {
            return {
                ...state,
                cropPresets: orderPresets(action.payload)
            };
        }
        case `${GET_USER_PAYMENTS}_FULFILLED`: {
            return { ...state, payments: action.payload };
        }
        case `${SET_USER_LOCALE}_FULFILLED`: {
            const user = { ...state.user, locale: action.payload };
            return {
                ...state,
                user
            };
        }
        case `${INIT_USER_PASSWORD}_FULFILLED`: {
            const user = {
                ...state.user,
                isPasswordSet: action.payload
            };
            return {
                ...state,
                authenticated: true,
                passwordSetDone: true,
                user
            };
        }
        default:
            return state;
    }
}
