import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader/Loader';
import styles from './Button.module.scss';

export default class Button extends Component {
    getLoaderColor() {
        const { design } = this.props;
        const designsWidthWhiteTexts = ['confirm', 'danger', 'orange', 'black '];
        if (designsWidthWhiteTexts.indexOf(design) > -1) {
            return 'white';
        }
        return 'grey';
    }

    handleClick = (e) => {
        if (this.props.isDisabledWithClick) {
            this.props.onDisabledClick?.(e);
            return;
        }
        this.props.onClick?.(e);
    };

    render() {
        const {
            disabled,
            value,
            size,
            style,
            children,
            design,
            className,
            isFull,
            type,
            isLoading,
            isRounded,
            isDisabledWithClick
        } = this.props;

        return (
            <button
                disabled={disabled}
                value={value}
                onClick={this.handleClick}
                type={type || 'button'}
                style={style || {}}
                data-cy={this.props['data-cy']}
                className={`
                  font-regular
                  ${styles.btn}
                  ${size ? styles[size] : styles.medium}
                  ${design ? styles[design] : styles.default}
                  ${isDisabledWithClick ? styles.disabled : ''}
                  ${isFull ? styles.block : ''}
                   ${isRounded ? styles.rounded : ''}
                  ${className || ''}`}
            >
                {isLoading ? (
                    <Loader design={this.getLoaderColor()} />
                ) : (
                    <div className={styles.content}>{children}</div>
                )}
            </button>
        );
    }
}

Button.propTypes = {
    children: PropTypes.any,
    disabled: PropTypes.bool,
    isFull: PropTypes.bool,
    onClick: PropTypes.func,
    onDisabledClick: PropTypes.func,
    isDisabledWithClick: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    isLoading: PropTypes.bool,
    type: PropTypes.string,
    isRounded: PropTypes.bool,
    'data-cy': PropTypes.string,
    size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge']),
    design: PropTypes.oneOf([
        'default',
        'grey',
        'confirm',
        'danger',
        'white',
        'orange',
        'dashed',
        'text',
        'black',
        'disabled'
    ]),
    value: PropTypes.string
};
