const initOneTrust = () => {
    const oneTrustScript1 = document.createElement('script');
    const oneTrustScript2 = document.createElement('script');

    oneTrustScript1.type="text/javascript"
    oneTrustScript1.src = "https://cdn-au.onetrust.com/scripttemplates/otSDKStub.js";
    oneTrustScript1.setAttribute('data-domain-script', 'e201ae45-dc28-4acb-a871-515cf20ec896');

    oneTrustScript2.type="text/javascript"
    oneTrustScript2.id="1"
    oneTrustScript2.append('function OptanonWrapper() { }');

    document.head.prepend(oneTrustScript1, oneTrustScript2);
}

export default initOneTrust;
