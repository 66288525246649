import Auth from '@aws-amplify/auth';
import { isServer } from '../utils/envDetection';

export function createCORSRequest(method, url) {
    /* eslint-disable no-undef */
    let xhr = new XMLHttpRequest();

    if (xhr.withCredentials !== null) {
        xhr.open(method, url, true);
    } else if (typeof XDomainRequest !== 'undefined') {
        xhr = new XDomainRequest();
        xhr.open(method, url);
    } else {
        xhr = null;
    }

    return xhr;
    /* eslint-enable no-undef */
}

export async function getUserToken() {
    const token = localStorage.getItem('user_token');
    const expire = parseInt(localStorage.getItem('user_token_expire'));

    const now = new Date().getTime();

    if (token && !Number.isNaN(expire) && now - expire < 0) {
        return token;
    }

    try {
        const currentAuthUser = await Auth.currentAuthenticatedUser();
        if (!currentAuthUser) {
            return null;
        }

        // grab the user session
        const session = await Auth.userSession(currentAuthUser);
        if (!session) {
            return null;
        }

        saveTokenToLocalStorage(session.getIdToken().getJwtToken());
        return localStorage.getItem('user_token');
    } catch {
        return null;
    }
}

export function saveTokenToLocalStorage(token) {
    const currentToken = localStorage.getItem('user_token');
    if (currentToken === token) {
        return;
    }
    const date = new Date();
    date.setHours(date.getHours() + 1);
    localStorage.setItem('user_token', token);
    localStorage.setItem('user_token_expire', date.getTime().toString());
}

export function getUserLocalData() {
    const userData = getCookie('userData');
    if (!userData) return null;
    let userDataObject = decodeURIComponent(userData);

    // to handle the old cookies that were not stored encoded
    try {
        userDataObject = JSON.parse(userDataObject);
    } catch {
        saveUserLocalData();
        return null;
    }

    return userDataObject;
}

export function saveUserLocalData(userData) {
    const currentLocalUser = getCookie('userData');
    if (currentLocalUser === userData) {
        return;
    }
    const localUser = encodeURIComponent(JSON.stringify(userData));
    setCookie('userData', localUser, 30);
}

export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/;SameSite=Lax;`;
}

export function getCookie(cname) {
    if (isServer()) return '';
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
