import isEqual from 'lodash/isEqual';
import {
    FETCH_CANVA_IMAGES,
    DELETE_CANVA_IMAGE,
} from '../actions/actionTypes';

const INITIAL_CANVA_IMAGES = { images: [], pagination: null };

const INITIAL_STATE = {
    canvaImages: INITIAL_CANVA_IMAGES,
    isFilepickerLoading: false,
};

export default function integrationsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case `${FETCH_CANVA_IMAGES  }_PENDING`:
            return {
                ...state,
                isFilepickerLoading: true
            };
        case `${FETCH_CANVA_IMAGES  }_FULFILLED`:
            return {
                ...state,
                canvaImages: mergeCanvaImages(state.canvaImages, action.payload),
                isFilepickerLoading: false
            };

        case `${DELETE_CANVA_IMAGE  }_FULFILLED`:
            // eslint-disable-next-line no-case-declarations
            const update = { ...state.canvaImages};
            update.images = update.images.filter(item => item.timestamp !== action.payload);
            return {
                ...state,
                canvaImages: update
            };

        default:
            return state;
    }
}

function mergeCanvaImages(current, update) {
    const res = { ...current};

    if (!isEqual(res.images, update.images)) {
        res.images = [...res.images, ...update.images];
    }

    res.pagination = update.pagination;
    return res;
}
