import { createStore, applyMiddleware } from 'redux';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import promise from 'redux-promise-middleware';
import reduxThunk from 'redux-thunk';

import { isClient } from '../utils/envDetection';
import combinedReducer from '../reducers/reducers';

/* eslint-disable global-require */
/* eslint-disable import/prefer-default-export */
const bindMiddleware = (middleware) => {
    if (process.env.NODE_ENV !== 'production') {
        const { composeWithDevTools } = require('redux-devtools-extension');
        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

const reducer = (state, action) => {
    if (action.type === HYDRATE) {
        const returnState = {
            ...state,
            server: {
                ...state.server,
                ...action.payload.server
            }
        };
        return returnState;
    }
    return combinedReducer(state, action);
};

const initStore = () => {
    const store = createStore(reducer, bindMiddleware([promise, reduxThunk]));

    if (isClient() && window.Cypress) {
        window.store = store;
    }
    return store;
};

export const wrapper = createWrapper(initStore);
