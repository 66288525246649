import { getWebglSupportStatus } from '../utils/featureDetector';
import { hideTrialModalApi } from '../apis/userApi';
import { fetchPlanPrices } from '../apis/plansApi';
import axios from 'axios';

import {
    INC_DRAG,
    DEC_DRAG,
    CANCEL_DRAG,
    GET_HOMEPAGE_DATA,
    SET_PREVIOUS_LOCATION,
    SET_WEBGL_SUPPORT,
    SET_PREMIUM_MODAL,
    HIDE_TRIAL_MODAL,
    GET_PLANS_PRICES,
    SET_ALERT,
    SET_LANGUAGE,
    SET_LANGUAGE_SERVER,
    SET_ACTIVE_COLLECTION,
    SET_TOTAL_COUNT_PADDLE,
    SET_PADDLE_EVENT,
    SET_PADDLE_ACTIVE,
    GET_HOMEPAGE_MOCKUPS,
    SET_INDEXED_PREVIEW_HISTORY,
    SET_INDEXED_PREVIEW_HISTORY_COUNT,
    SET_INDEXED_PREVIEW_HISTORY_REFRESH
} from './actionTypes';
import { isClient, isServer } from '../utils/envDetection';
import serviceClient from '../apis/ServiceClient';
import { HOMEPAGE_API } from '../utils/variables';
import {getMockups} from "../apis/mockupsApi";

export function increaseDrag() {
    return {
        type: INC_DRAG,
        payload: null
    };
}

export function decreaseDrag() {
    return {
        type: DEC_DRAG,
        payload: null
    };
}

export function cancelDrag() {
    return {
        type: CANCEL_DRAG,
        payload: null
    };
}

export function setPreviousLocation(location) {
    return {
        type: SET_PREVIOUS_LOCATION,
        payload: location
    };
}

export function setActiveCollection(slug) {
    return {
        type: SET_ACTIVE_COLLECTION,
        payload: slug
    };
}

export function setPremiumModal(type, source) {
    return {
        type: SET_PREMIUM_MODAL,
        payload: { type, source: source || null }
    };
}

export function hideTrialModal(username) {
    const request = hideTrialModalApi(username);
    return {
        type: HIDE_TRIAL_MODAL,
        payload: request
    };
}

export function getWebglSupport() {
    return {
        type: SET_WEBGL_SUPPORT,
        payload: getWebglSupportStatus()
    };
}

export function getPlansPrices() {
    const request = fetchPlanPrices();
    return {
        type: GET_PLANS_PRICES,
        payload: request
    };
}

export async function getPlansPricesServer() {
    const res = await fetchPlanPrices();
    return {
        type: GET_PLANS_PRICES,
        payload: res
    };
}

export async function getHomepageDataServer() {
    const res = await serviceClient.get(`${HOMEPAGE_API}/homepage`);
    return {
        type: GET_HOMEPAGE_DATA,
        payload: res
    };
}

export async function getHomepageMockupsServer(shortIds) {
    const res = await getMockups(shortIds);
    return {
        type: GET_HOMEPAGE_MOCKUPS,
        payload: res
    };
}

export function setAlert(alertId, alertData) {
    return {
        type: SET_ALERT,
        payload: { alertId, alertData }
    };
}

export function setLanguage(locale) {
    const request = changeLangAsync(locale);

    return {
        type: SET_LANGUAGE,
        payload: request
    };
}
export async function setLanguageServer(locale) {
    const data = await changeLangAsync(locale);
    return {
        type: SET_LANGUAGE_SERVER,
        payload: data
    };
}

async function changeLangAsync(locale) {
    // set locale to document
    // this is kind of a hack, but it seems to be the best solution according to
    // https://github.com/vercel/next.js/issues/9160
    if (isClient()) {
        document.documentElement.lang = locale;
    }

    const translates = await getLocalisedStrings(locale);

    return {
        locale,
        translates
    };
}

async function getLocalisedStrings(locale) {
    try {
        if (process.env.NODE_ENV !== 'production' || isServer()) {
            return await requireLocalStrings(locale);
        }

        if (locale === 'en') {
            const res = await Promise.resolve(axios.get(`/menu/menu_${locale}.json`));
            return [null, res.data];
        }

        const res = await Promise.all([
            axios.get(`/translations/${locale}.json`),
            axios.get(`/menu/menu_${locale}.json`)
        ]);

        return [res[0].data, res[1].data];
    } catch (err) {
        console.error(err);
        return {};
    }
}

export function setTotalCountPaddle(total) {
    return {
        type: SET_TOTAL_COUNT_PADDLE,
        payload: total
    };
}

export function setPaddleEventCallback(eventData) {
    return {
        type: SET_PADDLE_EVENT,
        payload: eventData
    };
}

export function setPaddleActive(isActive) {
    return {
        type: SET_PADDLE_ACTIVE,
        payload: isActive
    }
}

export function setIndexedPreviewHistory(preview) {
    return {
        type: SET_INDEXED_PREVIEW_HISTORY,
        payload: preview
    }
}

export function setIndexedPreviewHistoryCount(count) {
    return {
        type: SET_INDEXED_PREVIEW_HISTORY_COUNT,
        payload: count
    }
}

export function setIndexedPreviewHistoryRefresh(isRefresh) {
    return {
        type: SET_INDEXED_PREVIEW_HISTORY_REFRESH,
        payload: isRefresh
    }
}


const requireLocalStrings = (locale) => {
    let strings = null;
    return new Promise((resolve) => {
        require.ensure([], (require) => {
            // hack to enable local language files
            if (locale !== 'en') {
                // eslint-disable-next-line import/no-dynamic-require
                strings = require(`../public/translations/${locale}.json`);
            }
            // eslint-disable-next-line import/no-dynamic-require
            const menu = require(`../public/menu/menu_${locale}.json`);
            resolve([strings, menu]);
        });
    });
};
