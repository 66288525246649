import {
    FETCH_MOCKUP,
    FETCH_MOCKUPS_FOR_CATEGORY,
    GET_HOMEPAGE_DATA,
    GET_HOMEPAGE_MOCKUPS,
    GET_PLANS_PRICES,
    SET_LANGUAGE_SERVER,
    FETCH_LANDING_PAGE,
    FETCH_LANDING_PAGES_PREVIEWS
} from '../actions/actionTypes';

const INITIAL_STATE = {
    menuItems: [],
    subcategoryTags: {},
    subcategoryLabels: {},
    totalMockups: 1803,
    homepageData: {},
    homepageMockups: [],
    latestMockups: [],
    mockups: [],
    mockup: {},
    screens: [],
    locale: 'en',
    localeStrings: null,
    planPrices: {
        527401: { amount: 108 / 12, symbol: '$', currency: 'USD' },
        527402: { amount: 14, symbol: '$', currency: 'USD' },
        542352: { amount: 19, symbol: '$', currency: 'USD' },
        542351: { amount: 24, symbol: '$', currency: 'USD' },
        527403: { amount: 348 / 12, symbol: '$', currency: 'USD' },
        575845: { amount: 84, symbol: '$', currency: 'USD' },
        575842: { amount: 124, symbol: '$', currency: 'USD' },
        575838: { amount: 164, symbol: '$', currency: 'USD' },
        575844: { amount: 828 / 12, symbol: '$', currency: 'USD' },
        575841: { amount: 1188 / 12, symbol: '$', currency: 'USD' },
        542357: { amount: 1548 / 12, symbol: '$', currency: 'USD' }
    },
    isSeasonalDiscount: false,
    seasonalDiscount: 5.99,
    isMockupLoading: true,
    landingPage: {},
    landingPageBlocks: [],
    hasLoadedLandingPage: false,
    landingPagesPreviews: [],
    hasLoadedLandingPagesPreviews: false,
};

export default function serverSideReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_HOMEPAGE_DATA:
            return { ...state, homepageData: action.payload };

        case GET_HOMEPAGE_MOCKUPS:
            return { ...state, homepageMockups: action.payload };

        case FETCH_MOCKUPS_FOR_CATEGORY:
        case `${FETCH_MOCKUPS_FOR_CATEGORY}_FULFILLED`:
            return {
                ...state,
                mockups: getMockups(action.payload),
                collection: getCollection(action.payload)
            };

        case FETCH_MOCKUP:
            return {
                ...state,
                mockup: action.payload,
                isMockupLoading: false
            };
        case GET_PLANS_PRICES:
            return {
                ...state,
                planPrices: action.payload.data
            };
        case SET_LANGUAGE_SERVER:
            return {
                ...state,
                locale: action.payload.locale,
                localeStrings: action.payload.translates[0],
                menuItems: action.payload.translates[1]
            };
        case FETCH_LANDING_PAGE:
        case `${FETCH_LANDING_PAGE}_FULFILLED`:
            return {
                ...state,
                landingPage: action.payload[0].items[0],
                landingPageBlocks: action.payload[1],
                hasLoadedLandingPage: true
            };
        case FETCH_LANDING_PAGES_PREVIEWS:
        case `${FETCH_LANDING_PAGES_PREVIEWS}_FULFILLED`:
            return {
                ...state,
                landingPagesPreviews: action.payload,
                hasLoadedLandingPagesPreviews: true
            };
        default:
            return state;
    }
}

function getMockups(data) {
    if (!data.mockups) return [];
    return data.mockups;
}

function getCollection(data) {
    return data;
}
