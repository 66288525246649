export const createEmptyScreenArrayForMockup = (mockup) => {
    let screens = [];
    // reset screens and set their number according to the corners
    if (mockup && mockup.screens) {
        screens = mockup.screens.map(() => {
            return null;
        });
    }
    return screens;
};
export const createColorMaskColorsForMockup = (mockup) => {
    if (!mockup) {
        return [];
    }

    if (mockup.colorMasks) {
        return mockup.colorMasks.map((mask) => {
            return mask.defaultColor ? mask.defaultColor : [255, 255, 255, 1]; // default color mask color is white
        });
    }

    // compatibility with legacy colorMask property
    if (mockup.colorMask) {
        return [[255, 255, 255, 1]];
    }

    return [];
};

export const mergeColorMasksForNewMockup = (mockup, currentMasks) => {
    const defaultMasks = createColorMaskColorsForMockup(mockup);

    if (!currentMasks) {
        return defaultMasks;
    }

    return defaultMasks.map((mask, index) => {
        return currentMasks[index] ? currentMasks[index] : mask;
    });
};

export const mergeScreensForNewMockup = (mockup, currentScreens) => {
    const newScreens = createEmptyScreenArrayForMockup(mockup);
    for (let i = 0; i < newScreens.length; i++) {
        if (currentScreens[i]) {
            newScreens[i] = { ...currentScreens[i] };
        }
    }
    return newScreens;
};
