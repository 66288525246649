import {
    SET_TOTAL_COUNT_PADDLE,
    SET_PADDLE_EVENT, SET_PADDLE_ACTIVE
} from '../actions/actionTypes';


const INITIAL_STATE = {
    total: null,
    eventData: null,
    isActive: false
};

export default function paddleReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_TOTAL_COUNT_PADDLE:
            return {
                ...state,
                total: action.payload
            };
        case SET_PADDLE_EVENT:
            return {
                ...state,
                eventData: action.payload
            };
        case SET_PADDLE_ACTIVE:
            return {
                ...state,
                isActive: action.payload
            };
        default:
            return state;
    }
}
