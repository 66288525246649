const SUPPORTED_LOCALES_LONG = ['en-US', 'cs-CZ', 'de-DE', 'es-ES', 'fr-FR', 'it-IT', 'pt-BR', 'ru-RU', 'zh-TW', 'id-ID', 'zh-CN'];
const SUPPORTED_LOCALES = ['en', 'cs', 'de', 'es', 'fr', 'it', 'pt', 'ru', 'zh', 'id-ID', 'zh-CN'];
const MOCKUPS_PREVIEWS_LOCALES = ['en', 'cs', 'de', 'es', 'fr', 'it', 'pt', 'ru', 'zh'];
const SHORTER_LOCALES = ['en', 'cs'];
const LOCALISED_ROUTES = [
    'custom-mockups/introduction.js',
    'index.js',
    'features',
    'signup/index.js',
    'pricing'
];
const LANGUAGE_OPTIONS = [
    {
        label: 'English',
        value: 'en'
    },
    {
        label: 'Deutsch',
        value: 'de'
    },
    {
        label: 'Español',
        value: 'es'
    },
    {
        label: 'Français',
        value: 'fr'
    },
    {
        label: 'Italiano',
        value: 'it'
    },
    {
        label: 'Português',
        value: 'pt'
    },
    {
        label: 'Русский',
        value: 'ru'
    },
    {
        label: '简体中文',
        value: 'zh'
    },
    {
        label: 'Čeština',
        value: 'cs'
    },
    {
        label: 'Bahasa Indonesia',
        value: 'id-ID'
    },
    {
        label: '中文(简体)',
        value: 'zh-CN'
    }
];

const DEFAULT_LOCALE = 'en';

module.exports = {
    SUPPORTED_LOCALES,
    SHORTER_LOCALES,
    LANGUAGE_OPTIONS,
    DEFAULT_LOCALE,
    LOCALISED_ROUTES,
    SUPPORTED_LOCALES_LONG,
    MOCKUPS_PREVIEWS_LOCALES
};
