import {
    DELETE_CUSTOM_MOCKUP,
    EMPTY_CUSTOM_MOCKUP,
    EMPTY_CUSTOM_MOCKUPS,
    FETCH_CUSTOM_MOCKUP,
    GET_CUSTOM_MOCKUP,
    UPDATE_CUSTOM_MOCKUP
} from '../actions/actionTypes';
import { saveArrayCount, saveArrayLocal } from '../utils/localStorageHelpers';

const INITIAL_STATE = {
    customMockups: [],
    customMockup: null,
    customMockupsLoaded: false,
    categories: []
};

export default function customMockupReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case `${FETCH_CUSTOM_MOCKUP}_FULFILLED`:
            console.log(action.payload);
            saveArrayCount(action.payload, 'customMockupCount');
            return {
                ...state,
                customMockups: action.payload,
                customMockupsLoaded: true,
                categories: getCustomMockupsCategories(action.payload)
            };
        case `${GET_CUSTOM_MOCKUP}_FULFILLED`:
            return {
                ...state,
                customMockup: action.payload
            };
        case `${DELETE_CUSTOM_MOCKUP}_FULFILLED`:
            saveArrayCount(action.payload, 'customMockupCount');
            return {
                ...state,
                customMockups: action.payload
            };
        case `${UPDATE_CUSTOM_MOCKUP}_FULFILLED`:
            return {
                ...state,
                customMockups: action.payload,
                categories: getCustomMockupsCategories(action.payload)
            };
        case EMPTY_CUSTOM_MOCKUP:
            return {
                ...state,
                customMockup: null
            };
        case EMPTY_CUSTOM_MOCKUPS:
            return {
                ...state,
                customMockups: []
            };

        default:
            return state;
    }
}

function getCustomMockupsCategories(customMockups) {
    const cats = {};

    customMockups.forEach((item) => {
        if (item.category) {
            if (cats.hasOwnProperty(item.category)) {
                cats[item.category]++;
            } else {
                cats[item.category] = 1;
            }
        }
    });

    const result = Object.keys(cats).map((key) => {
        return {
            title: key,
            count: cats[key],
            slug: encodeURI(key)
        };
    });

    saveArrayLocal(result, 'customMockupsCategories');

    return result;
}
