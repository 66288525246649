import React from 'react';

import Head from 'next/head';

import Link from '../../../components/Base/LinkLocalised/LinkLocalised';
import styles from '../../../styles/modules/NotFound.module.scss';
import Button from '../../../components/Base/Button/Button';
import Emoji from '../../../components/Base/Emoji/Emoji';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error,
            errorInfo
        });
    }

    showStack() {
        const { componentStack } = { ...this.state.errorInfo };
        const firstLine = componentStack.split('\n')[1]; // 0 is empty line
        return firstLine;
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <div className={styles.notFoundWrapper}>
                    <Head>
                        <title>
                            {`Oops, something went wrong |
                            Smartmockups`}
                        </title>
                    </Head>

                    <div className={styles.headlineWrapper}>
                        <h3 className="page-headline-sm">Error</h3>
                        <h1 className="page-headline-lg-smaller">Oops, something went wrong</h1>
                    </div>

                    <div className={styles.errorWrapper}>
                        <div>{this.state.error && this.state.error.toString()}</div>
                        <div>{this.showStack()}</div>
                    </div>

                    <Link to="/">
                        <Button design="white" size="large">
                            <Emoji symbol="👈 " />
                            Take me to homepage
                        </Button>
                    </Link>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}
