import Router from 'next/router';

/* eslint-disable global-require */
export const SUPPORTED_LOCALES = require('./locale/localeVariables').SUPPORTED_LOCALES;
export const SHORTER_LOCALES = require('./locale/localeVariables').SHORTER_LOCALES;
export const LANGUAGE_OPTIONS = require('./locale/localeVariables').LANGUAGE_OPTIONS;
export const ROUTES_WITHOUT_LOCALISATION = require('./locale/localeVariables')
    .ROUTES_WITHOUT_LOCALISATION;

export const DEFAULT_LOCALE = require('./locale/localeVariables').DEFAULT_LOCALE;

export function isValidLocale(locale) {
    if (!locale || !locale.length || locale.length !== 2 || locale === DEFAULT_LOCALE) return false;

    return SUPPORTED_LOCALES.indexOf(locale) > -1;
}

export function pushBrowserHistoryLocalised(pathname, as = undefined, options = { scrollTo: 0 }) {
    if (!as) {
        Router.push(localisePathname(pathname), undefined, options).then(() =>
            window.scrollTo(0, options.scrollTo)
        );
    } else {
        Router.push(localisePathname(pathname), localisePathname(as), options).then(() =>
            window.scrollTo(0, options.scrollTo)
        );
    }
}

export function localisePathname(pathname) {
    // todo we should most likely translate the routes that are set to be localised
    return pathname;
}

export function removePathnameFromLocale(pathname) {
    const arr = pathname.split('/');

    if (arr[1] && isValidLocale(arr[1])) {
        return pathname.replace(`${arr[1]}/`, '');
    }

    return pathname;
}

export function getLocaleFromPathname(pathname) {
    // pathname = Router.router.pathname;
    const arr = pathname.split('/');

    if (arr[1] && isValidLocale(arr[1])) {
        return arr[1];
    }

    return DEFAULT_LOCALE;
}

export function getBrowserLocale() {
    const lang =
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;

    return lang.split('-')[0].toLocaleLowerCase();
}

// This would be used to load locale from router, using /page/[lang]
// however no working solution to avoid unwanted page refresh was found
// export const localizePathname = (path, type) => {
//     if (!Router.router) return path;
//
//     const { lang } = Router.router.query;
//     if (typeof lang === "undefined") return path;
//
//     //check for valid lang
//     if (!isValidLocale(lang)) return path;
//
//     switch (type) {
//         case "href":
//             path = `/[${lang}]${path}`;
//             break;
//         case "as":
//             path = `/${lang}${path}`;
//     }
//
//     console.log(path);
//
//     return path;
// };
